<template>
    <v-container fluid no-gutters>
        <v-data-table
            item-key="id"
            :headers="hideActions ? (deleteAction? headers.concat(deleteActionHeader) : headers) : (type === 'match' ? headers.concat(actions).concat([{ text: 'Stats', value: 'Stats' }]) :  headers.concat(actions))"
            :items="items"
            :items-per-page="size"
            :loading="isLoading"
            hide-default-footer
            disable-sort
            :mobile-breakpoint="0"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td
                        v-for="header in headers"
                        :key="header.value"
                    >
                        <span v-if="header.text === 'categories'"> {{ getCategories(props.item) }}</span>
                        <span v-else-if="header.text === 'League'"> {{ props.item[header.value] !== null ? props.item[header.value].title : '-' }}</span>
                        <span v-else-if="header.text === 'Cup'"> <v-icon :color="props.item['cup'] ? 'green' : 'red'"> {{ props.item['cup'] ? 'mdi-check' : 'mdi-close' }}</v-icon> </span>
                        <span v-else-if="header.value === 'noOfMatches'"> {{ `#MatchesRule${props.item[header.value].slice(-1)}` }}</span>
                        <span v-else-if="header.text === 'Free'"> <v-icon :color="props.item[header.value] ? 'green' : 'red'"> {{ props.item[header.value] ? 'mdi-check' : 'mdi-close' }} </v-icon> </span>
                        <span v-else-if="header.text === 'Image' || header.text === 'History Points Icon Image'"> <v-img contain width="200px" height="100px" :src="props.item[header.value]" /></span>
                        <span v-else-if="header.text.includes('Date')"> {{ getDate(props.item[header.value], type === 'match') }}</span>
                        <span v-else>{{ header.subLevel? props.item[header.value][header.subLevel] : props.item[header.value] }}</span>
                    </td>
                    <td v-if="!hideActions">
                        <v-btn icon class="mx-2" dark small color="black" @click="handleClick('edit', props.item)">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </td>
                    <td v-if="!hideActions || deleteAction">
                        <delete-button :type="false" @delete-entry="onDelete(props.item, localPage)" />
                    </td>
                    <td v-if="type === 'match'">
                        <v-btn icon class="mx-2" dark small color="black" @click="$router.push( `/stats/match/${props.item.id}`)">
                            <v-icon dark>mdi-chart-bar</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <custom-dialog :dialog="showDialog" @update="showDialog = !showDialog" />
        <v-row v-if="totalPages" class="pagination">
            <v-col cols="12" md="11" lg="11">
                <v-pagination
                    v-model="localPage"
                    :length="totalPages"
                    total-visible="7"
                    color="black"
                    circle
                    @input="paginationChange"
                />
            </v-col>
            <v-col cols="6" sm="2" md="1" lg="1">
                <v-select
                    v-model="localSize"
                    :hint="`Items per page: ${localSize}`"
                    :items="sizes"
                    label="Select"
                    persistent-hint
                    return-object
                    single-line
                    @input="sizeChange"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CustomDialog from '@/components/Dialog'
    import momentTimezone from 'moment-timezone'
    import DeleteButton from '@/components/DeleteButton'

    export default {
        components: { CustomDialog, DeleteButton },
        props: {
            items: {
                type: Array,
                default: null
            },
            headers: {
                type: Array,
                default: null
            },
            totalPages: {
                type: Number,
                default: null
            },
            page: {
                type: Number,
                default: null
            },
            size: {
                type: Number,
                default: null
            },
            type: {
                type: String,
                default: null
            },
            hideActions: {
                type: Boolean,
                default: false
            },
            deleteAction: {
                type: Boolean,
                default: false
            }
        },
        data: () => {
            return {
                localPage: 1,
                localSize: 10,
                sizes: [5, 10, 30, 60, 100],
                message: 'Please verify your ',
                role: '',
                showDialog: false,
                images: [
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/852099ce-b105-48e4-b97a-5b6fd14d7ddf/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/20784a41-1187-4979-af37-64eea1843fd6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/fa3de888-3106-4f49-a1a0-53f3e6864bf6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5,q_80/e46d4b68-5974-4781-b5f9-f317c50f87b6/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' },
                    { url: 'https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/852099ce-b105-48e4-b97a-5b6fd14d7ddf/%CE%B1%CE%BD%CE%B4%CF%81%CE%B9%CE%BA%CE%BF-%CF%80%CE%B1%CF%80%CE%BF%CF%85%CF%84%CF%83%CE%B9-air-max-270-fFBpMM.png' }
                ],
                actions: [
                    {
                        text: 'Edit',
                        value: 'Edit'
                    },
                    {
                        text: 'Delete',
                        value: 'delete'
                    }
                ],
                deleteActionHeader: [
                    {
                        text: 'Delete',
                        value: 'delete'
                    }
                ],
                isLoading: true
            }
        },
        watch: {
            items() {
                if (Array.isArray(this.items)) {
                    this.isLoading = false
                }
            },
            page() {
                this.localPage = this.page
            }
        },
        created() {
            this.localPage = this.page
            this.localSize = this.size
        },
        methods: {
            onDelete(item, page, parent) {
                this.$emit('onDelete', item, page, parent)
            },
            handleClick(action, item) {
                this.$emit('handleClick', action, item)
            },
            paginationChange() {
                this.$emit('paginationChange', this.localPage, this.localSize)
            },
            sizeChange() {
                this.localPage = 1
                this.paginationChange()
            },
            getCategories(item) {
                let categories = []
                item.categories.forEach(element => { categories.push(element.alias) })
                return categories.join(', ')
            },
            getDate(date, isMatch) {
                return isMatch ? momentTimezone(date).tz('Europe/Athens').format('DD/MM/YYYY HH:mm').toString() : momentTimezone(date).tz('Europe/Athens').format('DD/MM/YYYY').toString()
            }
        }
    }
</script>

<style scoped>
    .pagination {
        justify-content: center;
    }

    .product-photos {
        display: inline-block;
        vertical-align:middle;
    }

    .close-icon {
        background: red;
        padding: 0.1rem;
        border-radius: 50%;
        text-align: center;
        line-height: auto;
        vertical-align: middle;
    }

    .check-icon {
        background: green;
        padding: 0.1rem;
        border-radius: 50%;
        text-align: center;
        line-height: auto;
        vertical-align: middle;
    }

</style>
